import React from "react";

const NotFound = () => {
    return (
        <div className="page-container">
            <h1> Page not found</h1>
        </div>
        
        )
}

export default NotFound;