import React from "react";
import Form from '../components/Forms/Form.jsx'
import '../components/Forms/form.css'
// import picture from '../pics/pain.jpg'

const Booking = () => {
    return (
        <div className="page-container">
                   
            <Form />
        </div>
        
        )
}

export default Booking;